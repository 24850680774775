<template>
	<div class="supplier-quote-details position-relative w-100 h-100 px-4 py-3 d-flex justify-content-center overflow-hidden">
		<b-modal v-model="modal" centered hide-header-close
		         ok-variant="danger"
		         cancel-variant="custom-light"
		         v-bind:ok-disabled="!password || !quoteNo"
		         v-on:ok="deleteSupplierQuote"
		         v-on:hide="resetModal">
			<h3 slot="modal-title">Delete Supplier Quote</h3>
			<h5 slot="modal-ok" class="m-0">Confirm</h5>
			<h5 slot="modal-cancel" class="m-0">Cancel</h5>
			<form action="" class="container-modal-form position-relative w-100 h-100 p-3">
				<div class="form-group position-relative">
					<label for="password" class="font-weight-bold">Password</label>
					<input type="password" id="password" class="form-control position-relative w-100"
					       v-bind:class="{'invalid': pwInvalid}"
					       v-model="password"
					       v-on:input="pwInvalid = false">
				</div>
				<div class="form-group position-relative">
					<label for="modalSupplierQuoteNo" class="font-weight-bold">Quote No.</label>
					<input type="text" id="modalSupplierQuoteNo" class="form-control position-relative w-100"
					       v-bind:class="{'invalid': quoteNoInvalid}"
					       v-model="quoteNo"
					       v-on:input="quoteNoInvalid = false">
				</div>
			</form>
		</b-modal>
		<div class="details position-relative w-50 h-100 rounded bg-white d-flex flex-column align-items-center flex-grow-0 overflow-hidden">
			<form-controls v-bind:msg="msg"
			               v-bind:msg-invalid="msgInvalid">
				<control-button slot="left"
				                v-bind:disabled="false"
				                v-bind:btn-title="backBtnTitle"
				                v-bind:btn-icon="['fas', 'arrow-left']"
				                v-bind:btn-class="backBtnClass"
				                v-on:btn-pressed="goBack"></control-button>
				<control-button slot="right"
				                v-bind:disabled="!savable"
				                btn-title="SAVE"
				                v-bind:btn-icon="['fas', 'hdd']"
				                btn-class="btn-custom-light ml-2"
				                v-on:btn-pressed="saveSupplierQuote"></control-button>
				<control-button slot="right"
				                v-bind:disabled="!supplierQuote"
				                btn-title="DELETE"
				                v-bind:btn-icon="['fas', 'trash-alt']"
				                btn-class="btn-danger ml-2"
				                v-on:btn-pressed="modal = true"></control-button>
			</form-controls>
			<form action="" class="container-form position-relative scroll-bar w-100 h-100 p-3">
				<div class="form-group position-relative w-100">
					<label for="supplierQuoteNo" class="font-weight-bold">Quote No.</label>
					<input type="text" id="supplierQuoteNo" placeholder=""
					       class="form-control position-relative"
					       v-bind:value="localSupplierQuote.quoteNo" readonly>
				</div>
				<div class="form-group position-relative w-100">
					<label for="supplier" class="font-weight-bold">Supplier</label>
					<input type="text" id="supplier" class="form-control position-relative"
					       placeholder="Choose Supplier From The Table"
					       v-bind:class="invalidInputs.supplier"
					       v-bind:value="localSupplierQuote.supplierName" readonly>
				</div>
				<div class="subform position-relative w-100 h-auto mb-3 border border-custom-light rounded"
				     v-for="(item, index) in localSupplierQuote.items" v-bind:key="index">
					<div class="controls position-relative w-100 p-2 d-flex justify-content-center align-items-center">
						<button type="button" class="button btn btn-custom-light p-0 flex-shrink-0"
						        v-bind:disabled="localSupplierQuote.items.length === 1"
						        v-on:click="addDeleteItems('delete', index)">
							<font-awesome-icon class="position-relative w-90 h-100"
							                   v-bind:icon="['fas', 'minus']">
							</font-awesome-icon>
						</button>
						<h4 class="title position-relative px-4 m-0 text-truncate">Item {{index + 1}}</h4>
						<button type="button" class="button btn btn-custom-light p-0 flex-shrink-0"
						        v-bind:disabled="localSupplierQuote.items.length >= 50"
						        v-on:click="addDeleteItems('add', index)">
							<font-awesome-icon class="position-relative w-90 h-100"
							                   v-bind:icon="['fas', 'plus']">
							</font-awesome-icon>
						</button>
					</div>
					<div class="content position-relative w-100 p-3">
						<div class="form-group position-relative w-100">
							<label v-bind:for="`item-name${index}`" class="font-weight-bold">Name</label>
							<input type="text" v-bind:id="`item-name${index}`" placeholder="Item Name"
							       class="form-control position-relative"
							       v-bind:class="invalidItems[index].name"
							       v-bind:value="item.name"
							       v-on:input="updateItems('name', $event.target.value, index)">
						</div>
						<div class="form-group position-relative w-100">
							<label v-bind:for="`item-quantity${index}`" class="font-weight-bold">Quantity</label>
							<input type="text" v-bind:id="`item-quantity${index}`" placeholder="Quantity"
							       class="form-control position-relative"
							       v-bind:class="invalidItems[index].quantity"
							       v-bind:value="item.quantity"
							       v-on:input="updateItems('quantity', $event.target.value, index)">
						</div>
						<div class="form-group position-relative w-100">
							<label v-bind:for="`item-unitPrice${index}`" class="font-weight-bold">Unit Price</label>
							<input type="text" v-bind:id="`item-unitPrice${index}`" placeholder="Unit Price"
							       class="form-control position-relative"
							       v-bind:class="invalidItems[index].unitPrice"
							       v-bind:value="item.unitPrice"
							       v-on:change="updateItems('unitPrice', $event.target.value, index)">
						</div>
					</div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="currency" class="font-weight-bold">Currency</label>
					<select id="currency" class="form-control position-relative"
					        v-bind:class="invalidInputs.currency"
					        v-bind:value="localSupplierQuote.currency"
					        v-on:change="updateSupplierQuote('currency', $event.target.value)">
						<option disabled hidden value=""></option>
						<option>HKD</option>
						<option>USD</option>
						<option>RMB</option>
						<option>JPY</option>
						<option>CAD</option>
					</select>
				</div>
				<div class="form-group position-relative w-100">
					<label for="serviceFee" class="font-weight-bold">Service Fee</label>
					<input type="text" id="serviceFee" placeholder="Service Fee"
					       class="form-control position-relative"
					       v-bind:class="invalidInputs.serviceFee"
					       v-bind:value="localSupplierQuote.serviceFee"
					       v-on:change="updateSupplierQuote('serviceFee', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="shippingFee" class="font-weight-bold">Shipping Fee</label>
					<input type="text" id="shippingFee" placeholder="Shipping Fee"
					       class="form-control position-relative"
					       v-bind:class="invalidInputs.shippingFee"
					       v-bind:value="localSupplierQuote.shippingFee"
					       v-on:change="updateSupplierQuote('shippingFee', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="otherCharges" class="font-weight-bold">Other Charges</label>
					<input type="text" id="otherCharges" placeholder="Other Charges"
					       class="form-control position-relative"
					       v-bind:class="invalidInputs.otherCharges"
					       v-bind:value="localSupplierQuote.otherCharges"
					       v-on:change="updateSupplierQuote('otherCharges', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="tax" class="font-weight-bold">Tax</label>
					<input type="text" id="tax" placeholder="Tax"
					       class="form-control position-relative"
					       v-bind:class="invalidInputs.tax"
					       v-bind:value="localSupplierQuote.tax"
					       v-on:change="updateSupplierQuote('tax', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="discount" class="font-weight-bold">Discount</label>
					<input type="text" id="discount" placeholder="Discount"
					       class="form-control position-relative"
					       v-bind:class="invalidInputs.discount"
					       v-bind:value="localSupplierQuote.discount"
					       v-on:change="updateSupplierQuote('discount', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="totalAmount" class="font-weight-bold">Total Amount</label>
					<input type="text" id="totalAmount" placeholder="0.00"
					       class="form-control position-relative"
					       v-bind:value="localSupplierQuote.totalAmount" readonly>
				</div>
				<div class="form-group position-relative w-100">
					<label for="deliverySchedule" class="font-weight-bold">Delivery Schedule</label>
					<input type="text" id="deliverySchedule" placeholder="Delivery Schedule"
					       class="form-control position-relative"
					       v-bind:class="invalidInputs.deliverySchedule"
					       v-bind:value="localSupplierQuote.deliverySchedule"
					       v-on:input="updateSupplierQuote('deliverySchedule', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="paymentTerms" class="font-weight-bold">Payment Terms</label>
					<input type="text" id="paymentTerms" placeholder="Payment Terms"
					       class="form-control position-relative"
					       v-bind:class="invalidInputs.paymentTerms"
					       v-bind:value="localSupplierQuote.paymentTerms"
					       v-on:input="updateSupplierQuote('paymentTerms', $event.target.value)">
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="receivedAtDate" class="font-weight-bold text-truncate">Date Received</label>
						<b-form-datepicker id="receivedAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:class="invalidInputs.receivedAtDT.date"
						                   v-bind:value="localSupplierQuote.receivedAtDT.date"
						                   v-on:input="updateDT('receivedAt', 'receivedAtDT', 'date', $event)">
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="receivedAtTime" class="font-weight-bold text-truncate">Time</label>
						<b-form-timepicker id="receivedAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:class="invalidInputs.receivedAtDT.time"
						                   v-bind:value="localSupplierQuote.receivedAtDT.time"
						                   v-on:input="updateDT('receivedAt', 'receivedAtDT', 'time', $event)">
						</b-form-timepicker>
					</div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="notes" class="font-weight-bold">Notes</label>
					<textarea id="notes" rows="6" class="form-control position-relative"
					          v-model="localSupplierQuote.notes"></textarea>
				</div>
				<div class="form-group position-relative w-100">
					<label for="updatedBy" class="font-weight-bold">Quote Updated By</label>
					<input type="text" id="updatedBy" class="form-control position-relative"
					       placeholder="Quote Not Yet Saved"
					       v-bind:value="localSupplierQuote.updatedByName" readonly>
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="updatedAtDate" class="font-weight-bold text-truncate">Date Updated</label>
						<b-form-datepicker id="updatedAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:value="localSupplierQuote.updatedAtDT.date" disabled>
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="updatedAtTime" class="font-weight-bold text-truncate">Time Updated</label>
						<b-form-timepicker id="updatedAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:value="localSupplierQuote.updatedAtDT.time" disabled>
						</b-form-timepicker>
					</div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="createdBy" class="font-weight-bold">Quote Created By</label>
					<input type="text" id="createdBy" class="form-control position-relative"
					       placeholder="Quote Not Yet Created"
					       v-bind:value="localSupplierQuote.createdByName" readonly>
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="createdAtDate" class="font-weight-bold text-truncate">Date Created</label>
						<b-form-datepicker id="createdAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:value="localSupplierQuote.createdAtDT.date" disabled>
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="createdAtTime" class="font-weight-bold text-truncate">Time Created</label>
						<b-form-timepicker id="createdAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:value="localSupplierQuote.createdAtDT.time" disabled>
						</b-form-timepicker>
					</div>
				</div>
			</form>
		</div>
		<div class="table-selects position-relative w-50 h-100 ml-3 rounded d-flex flex-column">
			<table-select title="Supplier" filter="name" sort="name"
			              v-bind:order="1"
			              v-bind:fields="suppliersFields"
			              v-bind:data-array="suppliers"
			              v-bind:selectable="true"
			              v-bind:multi-selectable="false"
			              v-bind:selected-ids="[localSupplierQuote.supplier]"
			              v-on:row-clicked="selectSupplier($event)"></table-select>
		</div>
	</div>
</template>

<script>
import FormControls from "@/components/functional/form/FormControls";
import TableSelect from "@/components/functional/table/TableSelect";
import ControlButton from "@/components/functional/form/ControlButton";
import { v4 as uuidv4 } from 'uuid';
import isNumeric from "validator/lib/isNumeric";
import { BModal, BFormDatepicker, BFormTimepicker } from "bootstrap-vue";
import { format } from "date-fns"
import { dateTimeDifferent, itemsDifferent, calculateCharges, discountInvalid } from "@/utility/helpers";

export default {
	name: "SupplierQuoteDetails",
	
	components: {
		FormControls,
		TableSelect,
		ControlButton,
		BModal,
		BFormDatepicker,
		BFormTimepicker
	},
	
	props: {
		// props from vue router route params
		supplierQuoteId: {
			type: String,
		}
	},
	
	beforeRouteLeave(to, from, next) {
		this.modal = false;
		if (this.savable) {
			if (this.msg === "Leave Without Saving?") {
				// user has already been notified
				next();
			} else {
				this.toRoute = to.fullPath;
				this.msg = "Leave Without Saving?";
				this.msgInvalid = true;
				this.backBtnTitle = "YES!";
				this.backBtnClass = "btn-danger mr-2";
			}
		} else {
			next();
		}
	},
	
	created() {
		this.initSupplierQuote();
		window.addEventListener("beforeunload", this.unloadHandler);
	},
	
	beforeDestroy() {
		window.removeEventListener("beforeunload", this.unloadHandler);
	},
	
	data() {
		return {
			localSupplierQuote: {
				_id: "",
				quoteNo: "",
				supplier: "",
				items: [
					{
						name: "",
						quantity: "",
						unitPrice: "",
					},
				],
				deliverySchedule: "",
				currency: "",
				serviceFee: "",
				shippingFee: "",
				tax: "",
				otherCharges: "",
				discount: "",
				totalAmount: "",
				paymentTerms: "",
				receivedAt: "",
				createdAt: "",
				updatedAt: "",
				createdBy: "",
				updatedBy: "",
				notes: "",
				// added properties
				supplierName: "",
				createdByName: "",
				updatedByName: "",
				receivedAtDT: { date: "", time: "" },
				createdAtDT: { date: "", time: "" },
				updatedAtDT: { date: "", time: "" },
			},
			// use v-model on input that does not require validation
			invalidInputs: {
				supplier: "",
				deliverySchedule: "",
				currency: "",
				paymentTerms: "",
				serviceFee: "",
				shippingFee: "",
				tax: "",
				otherCharges: "",
				discount: "",
				receivedAtDT: { date: "", time: "" },
			},
			invalidItems: [
				{
					name: "",
					quantity: "",
					unitPrice: "",
				},
			],
			suppliersFields: [
				{ display: "Name", key: "name", classBinding: ["f-5"] },
				{ display: "Supplier No.", key: "supplierNo", classBinding: ["f-2"] },
			],
			msg: "",
			msgInvalid: false,
			modal: false,
			toRoute: "",
			backBtnTitle: "BACK",
			backBtnClass: "btn-custom-light mr-2",
			password: "",
			pwInvalid: false,
			quoteNo: "",
			quoteNoInvalid: false,
		}
	},
	
	computed: {
		supplierQuote() {
			return this.$store.state.contents.supplierQuotations.find(({ _id }) => _id === this.supplierQuoteId);
		},
		
		savable() {
			// fall through pattern
			if (!this.supplierQuote) return true;
			// can safely access properties inside this.supplierQuote due to the first if clause in this function
			if (itemsDifferent(this.localSupplierQuote.items, this.supplierQuote.items)) return true;
			if (dateTimeDifferent(this.supplierQuote.receivedAt, this.localSupplierQuote.receivedAt)) return true;
			const keys = ["supplier", "deliverySchedule", "currency", "serviceFee", "shippingFee", "tax", "otherCharges",
				"discount", "paymentTerms", "notes"];
			return keys.some(key => this.localSupplierQuote[key] !== this.supplierQuote[key]);
		},
		
		suppliers() {
			return this.$store.state.contents.suppliers.map(({ _id, name, supplierNo }) => {
				return {
					_id: _id,
					name: name,
					supplierNo: supplierNo
				}
			});
		},
	},
	
	methods: {
		unloadHandler(event) {
			if (this.savable) {
				event.preventDefault();
				event.returnValue = '';
			}
		},
		
		resetModal(bvModalEvent) {
			if (bvModalEvent.trigger !== "ok") {
				this.password = "";
				this.pwInvalid = false;
				this.quoteNo = "";
				this.quoteNoInvalid = false;
			}
		},
		
		initSupplierQuote() {
			if (this.supplierQuote) {
				const obj = {...this.supplierQuote};
				obj.items = [];
				this.invalidItems = [];
				this.supplierQuote.items.forEach(item => {
					obj.items.push({...item});
					this.invalidItems.push({ name: "", quantity: "", unitPrice: "" });
				});
				const supplier = this.$store.state.contents.suppliers.find(({ _id }) => _id === obj.supplier);
				const createdBy = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.createdBy);
				const updatedBy = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.updatedBy);
				// receivedAt, createdAt and updatedAt must present in the database, need no nullish check
				const receivedAtDT = new Date(obj.receivedAt);
				const createdAtDT = new Date(obj.createdAt);
				const updatedAtDT = new Date(obj.updatedAt);
				// added properties
				obj.supplierName = supplier ? `${supplier.name} (${supplier.supplierNo})` : "";
				obj.createdByName = createdBy ? `${createdBy.lastName} ${createdBy.firstName}, ${createdBy.preferredName} (${createdBy.staffNo})` : "N/A";
				obj.updatedByName = updatedBy ? `${updatedBy.lastName} ${updatedBy.firstName}, ${updatedBy.preferredName} (${updatedBy.staffNo})` : "N/A";
				obj.receivedAtDT = { date: format(receivedAtDT, "yyyy-MM-dd"), time: format(receivedAtDT, "HH:mm:ss")};
				obj.createdAtDT = { date: format(createdAtDT, "yyyy-MM-dd"), time: format(createdAtDT, "HH:mm:ss")};
				obj.updatedAtDT = { date: format(updatedAtDT, "yyyy-MM-dd"), time: format(updatedAtDT, "HH:mm:ss")};
				this.localSupplierQuote = obj;
			} else {
				this.localSupplierQuote._id = uuidv4();
				this.localSupplierQuote.quoteNo = "QUO-" + this.localSupplierQuote._id.split("-")[0].toUpperCase() + "-SUP";
			}
		},
		
		addDeleteItems(option, index) {
			if (option === "add") {
				this.localSupplierQuote.items.splice(index + 1, 0, { name: "", quantity: "", unitPrice: "" });
				this.invalidItems.splice(index + 1, 0, { name: "", quantity: "", unitPrice: "" });
			} else {
				this.localSupplierQuote.items.splice(index, 1);
				this.invalidItems.splice(index, 1);
			}
		},
		
		selectSupplier(supplier) {
			this.localSupplierQuote.supplier = supplier._id;
			this.localSupplierQuote.supplierName = `${supplier.name} (${supplier.supplierNo})`;
			this.invalidInputs.supplier = "";
		},
		
		// this method also set discount class to invalid if discountInvalid returns true
		updateTotalAmount() {
			const totalCharges = calculateCharges(this.localSupplierQuote);
			if (discountInvalid(totalCharges, this.localSupplierQuote.discount)) {
				this.invalidInputs.discount = "invalid";
				this.localSupplierQuote.totalAmount = totalCharges.toFixed(2);
			} else {
				this.invalidInputs.discount = "";
				const totalAmount = totalCharges - parseFloat(this.localSupplierQuote.discount);
				this.localSupplierQuote.totalAmount = totalAmount.toFixed(2);
			}
		},
		
		updateSupplierQuote(key, value) {
			this.localSupplierQuote[key] = value;
			switch (key) {
				case "serviceFee":
				case "shippingFee":
				case "tax":
				case "otherCharges":
					if (isNumeric(value) && parseFloat(value) >= 0) {
						this.localSupplierQuote[key] = parseFloat(value).toFixed(2);
						this.invalidInputs[key] = "";
					} else {
						this.invalidInputs[key] = "invalid";
					}
					this.updateTotalAmount();
					break;
				case "discount":
					if (isNumeric(value) && parseFloat(value) >= 0) this.localSupplierQuote[key] = parseFloat(value).toFixed(2);
					this.updateTotalAmount();
					break;
				default:
					// check if value is just white spaces
					this.invalidInputs[key] = value.trim().length > 0 ? "" : "invalid";
					break;
			}
		},
		
		updateDT(key, keyDT, dateTime, value) {
			this.localSupplierQuote[keyDT][dateTime] = value;
			this.invalidInputs[keyDT][dateTime] = "";
			if (dateTime === "date") {
				this.localSupplierQuote[key] = this.localSupplierQuote[keyDT].time ?
					new Date(`${value}T${this.localSupplierQuote[keyDT].time}`).toISOString() : value;
			} else {
				this.localSupplierQuote[key] = this.localSupplierQuote[keyDT].date ?
					new Date(`${this.localSupplierQuote[keyDT].date}T${value}`).toISOString() : value;
			}
		},
		
		updateItems(key, value, index) {
			this.localSupplierQuote.items[index][key] = value;
			switch (key) {
				case "quantity":
					this.invalidItems[index][key] = isNumeric(value) && parseFloat(value) >= 0 ? "" : "invalid";
					this.updateTotalAmount();
					break;
				case "unitPrice":
					// bind to change event instead of input event
					if (isNumeric(value) && parseFloat(value) >= 0) {
						this.localSupplierQuote.items[index][key] = parseFloat(value).toFixed(2);
						this.invalidItems[index][key] = "";
					} else {
						this.invalidItems[index][key] = "invalid";
					}
					this.updateTotalAmount();
					break;
				default:
					this.invalidItems[index][key] = value.trim().length > 0 ? "" : "invalid";
					break;
			}
		},
		
		// only set invalid, shouldn't set it back to empty string
		// nullish check only, no other validations since they are being taken care of in input event handlers
		dataInvalid() {
			const quoteKeys = ["supplier", "deliverySchedule", "currency", "paymentTerms", "serviceFee", "shippingFee",
				"tax", "otherCharges", "discount"];
			const itemKeys = ["name", "quantity", "unitPrice"];
			quoteKeys.forEach(key => {
				if (!this.localSupplierQuote[key] ||
					this.localSupplierQuote[key].trim().length === 0) this.invalidInputs[key] = "invalid";
			});
			this.invalidItems.forEach((item, index) => itemKeys.forEach(key => {
				if (!this.localSupplierQuote.items[index][key] ||
					this.localSupplierQuote.items[index][key].trim().length === 0) item[key] = "invalid"; //object reference
			}));
			if (this.localSupplierQuote.receivedAt) {
				if (!this.localSupplierQuote.receivedAtDT.date) this.invalidInputs.receivedAtDT.date = "invalid";
				if (!this.localSupplierQuote.receivedAtDT.time) this.invalidInputs.receivedAtDT.time = "invalid";
			}
			return Object.keys(this.invalidInputs).some(key => {
				if (key === "receivedAtDT") {
					return this.invalidInputs.receivedAtDT.date === "invalid" ||
						this.invalidInputs.receivedAtDT.time === "invalid";
				} else {
					return this.invalidInputs[key] === "invalid";
				}
			}) || this.invalidItems.some(item => itemKeys.some(key => item[key] === "invalid"));
		},
		
		async saveSupplierQuote() {
			this.backBtnTitle = "BACK";
			this.backBtnClass = "btn-custom-light mr-2";
			if (this.dataInvalid()) {
				this.msg = "Please Fix Invalid Fields!";
				this.msgInvalid = true;
			} else {
				const deleteKeys = ["supplierName", "createdByName", "updatedByName",
					"receivedAtDT", "createdAtDT", "updatedAtDT"];
				const now = new Date().toISOString();
				const supplierQuote = {...this.localSupplierQuote};
				if (!supplierQuote.createdAt) {
					supplierQuote.createdAt = now;
					supplierQuote.createdBy = this.$store.state.user.user._id;
				}
				supplierQuote.updatedAt = now;
				supplierQuote.updatedBy = this.$store.state.user.user._id;
				deleteKeys.forEach(key => delete supplierQuote[key]);
				await this.$store.dispatch("contents/updateContent", { key: "supplierQuotations", value: supplierQuote});
				if (this.supplierQuoteId) {
					this.initSupplierQuote();
					this.msg = "Previous Changes Saved!";
					this.msgInvalid = false;
				} else {
					// bypassing beforeRouteLeave guard
					this.msg = "Leave Without Saving?";
					await this.$router.push(`/supplier/quotation/${supplierQuote._id}`);
					// need to run initSupplierQuote after route change since supplierQuoteId prop was undefined
					this.initSupplierQuote();
					// vue router reuses this component
					this.msgInvalid = false;
					this.msg = "New Supplier Quote Created!";
				}
			}
		},
		
		async deleteSupplierQuote(bvModalEvent) {
			if (this.password !== this.$store.state.user.user.password) {
				bvModalEvent.preventDefault();
				this.pwInvalid = true;
				if (this.quoteNo !== this.supplierQuote.quoteNo) this.quoteNoInvalid = true;
			} else if (this.quoteNo !== this.supplierQuote.quoteNo) {
				bvModalEvent.preventDefault();
				this.quoteNoInvalid = true;
			} else {
				await this.$store.dispatch("contents/deleteContent", { key: "supplierQuotations", _id: this.supplierQuote._id});
				// bypassing beforeRouteLeave guard
				this.msg = "Leave Without Saving?";
				await this.$router.push("/supplier/quotation");
			}
		},
		
		async goBack() {
			if (this.msg === "Leave Without Saving?") {
				await this.$router.push(this.toRoute);
			} else {
				await this.$router.push("/supplier/quotation");
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/form";
</style>